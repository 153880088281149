<template>
  <div class="nav-map-layers">
    <div class="p-2 pl-3">
      <div v-for="(layerName, index) in layers" :key="`${layerName}-${index}`">
        <div class="map-layer-toggle form-inline'">
          <input
            :id="`${layerName}-checkbox`"
            type="checkbox"
            :name="`${layerName}-checkbox`"
          />
          <label class="text-white" :for="`${layerName}-checkbox`">
            {{ layerName }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MapLayers } from "@/data/mapSettings";
export default {
  name: "NavMapLayers",
  components: {},
  data() {
    return {
      layers: MapLayers,
    };
  },
};
</script>
<style lang="scss" scoped>
label {
  color: #000;
  margin-left: 10px;
}
.c-dark-theme {
  label {
    color: #fff;
  }
}
</style>

<template>
  <div>
    <CRow>
      <div v-if="errors" class="form-group alert errorMsg">
        <ul class="errors">
          <li v-for="error in validationErrors" :key="error" class="errorMsg">
            {{ error }}
          </li>
        </ul>
      </div>
    </CRow>
    <div ref="form" class="edit-incident-form">
      <CRow class="fields-button pt-3">
        <CCol class="description-notes px-0" sm="11">
          <CRow>
            <CCol sm="12" class="pl-1">
              <CInput
                :value.sync="incident.title"
                addLabelClasses="label"
                placeholder="Enter incident title"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" class="pl-1">
              <CTextarea
                :value.sync="incident.notes"
                addLabelClasses="label"
                placeholder="Enter details here"
                class="bwx-textarea"
                rows="5"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol class="buttons mt-0 px-0" sm="1">
          <button class="arrow-button" @click.stop="$emit('close')">
            <img
              class="arrow-icon c-d-dark-none"
              src="@/assets/img/nav/chevron-down-light.svg"
            />
            <img
              class="arrow-icon c-d-default-none"
              src="@/assets/img/nav/chevron-down-dark.svg"
            />
          </button>
        </CCol>
      </CRow>
      <CRow>
        <pill-multi-select
          v-if="allUsers"
          tag="Add Assignees"
          :data.sync="incident.idsAssignedUser"
          :src="allUsers"
          type="user"
          @close-other-inputs="closeOtherInputs"
          ref="userInput"
        ></pill-multi-select>
      </CRow>
      <CRow>
        <pill-multi-select
          tag="Add Departments"
          :data.sync="incident.departments"
          :src="INCIDENT_DEPARTMENTS"
          type="department"
          @close-other-inputs="closeOtherInputs"
          ref="departmentInput"
        ></pill-multi-select>
      </CRow>
      <CRow>
        <pill-single-select
          v-if="allAssets"
          tag="Associated Asset"
          :data.sync="incident.idAsset"
          :src="allAssets"
          type="asset"
          @close-other-inputs="closeOtherInputs"
          ref="assetInput"
        ></pill-single-select>
      </CRow>
      <CRow>
        <pill-attachments
          tag="Attachments"
          :data.sync="incident.attachments"
          :src="INCIDENT_DEPARTMENTS"
        ></pill-attachments>
      </CRow>
      <CRow class="save-row ml-1">
        <CInputCheckbox class="is-complete" label="Incident is complete" />
        <div
          class="btn btn-outline-success save-button"
          @click.stop="handleSave"
        >
          Save
        </div>
      </CRow>
    </div>
    <div class="loading-wrapper">
      <loading
        :isFullPage="false"
        v-if="isLoading"
        :active="isLoading"
      ></loading>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { INCIDENT_DEPARTMENTS } from "@/data/types.js";
import editFormMixin from "@/mixins/editFormMixin";
import PillMultiSelect from "@/components/common/PillMultiSelect";
import PillSingleSelect from "@/components/common/PillSingleSelect";
import PillAttachments from "@/components/common/PillAttachments";
import Loading from "vue-loading-overlay";

export default {
  name: "EditIncident",
  components: {
    PillMultiSelect,
    PillSingleSelect,
    PillAttachments,
    Loading,
  },
  mixins: [editFormMixin],
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      INCIDENT_DEPARTMENTS,
      validationErrors: [],
      errors: false,
      isLoading: false,
      incident: {},
      newId: this.id,
      idsAssociatedTask: [],
      idsAssignedUser: [],
      allUsers: null,
      allAssets: null,
      attachments: [],
    };
  },
  validations: {
    incident: {
      title: {
        required,
      },
      department: {},
      notes: {},
      complete: {},
    },
  },
  created() {
    this.$store.dispatch("ui/setModalLoading", true);
    this.isLoading = true;

    this.$store.dispatch("user/fetchAll").then((response) => {
      // console.dir(response);
      this.allUsers = response.data;
    });
    this.allAssets = this.$store.state.map.assets;

    if (this.$route.name === "new-incident") {
      this.createNew();

      this.$store.dispatch("map/setIncidentTemp", {
        saved: false,
        id: this.newId,
      });
    } else {
      this.$store.dispatch("map/setIncidentTemp", {
        saved: true,
        id: this.newId,
      });

      //   this.$store.dispatch("incidents/fetchAttachments", this.newId).then((res) => {
      //     this.attachments = res.data;
      //   });

      this.$store.dispatch("incidents/fetchById", this.newId).then((res) => {
        if (res.data.idsAssignedUser)
          res.data.assignees = res.data.idsAssignedUser.map((e) => {
            return e.name;
          });

        this.incident = res.data;
      });
    }

    this.$store.dispatch("ui/setModalLoading", false);
    this.isLoading = false;
  },
  methods: {
    setLoading(value) {
      this.isLoading = value;
    },
    setAssociation(asset) {
      this.incident.idAsset = asset.id;
    },

    createNew() {
      // Create a new empty incident before the user provides input
      // Ensures an icon is displayed on the map immediately

      this.incident.title = "Temporary";

      let attachments = {}; //this.$refs.upDownManager.getUpdatedFiles()
      let data = {
        complete: false,
        ...this.incident,
        idMap: this.$route.query.mapId,
        attachments,
        temporary: true,
      };
      if (this.$route.params.latLong) {
        data.geoLocation = this.$route.params.latLong;
      }

      let vueParent = this;

      this.$store
        .dispatch("incidents/create", data)
        .then((res) => {
          vueParent.incident = res.data;

          vueParent.newId = res.data._id;

          vueParent.$store.dispatch("map/setIncidentTemp", {
            saved: false,
            id: vueParent.newId,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    deleteNew() {
      // Delete new incident if the user navigates away before providing
      // minimum content for the incident
    },

    saveExisting() {
      const data = {
        data: { ...this.incident },
        id: this.newId,
      };

      data.data.temporary = false;

      // console.dir(data);
      this.$store
        .dispatch("incidents/updateById", data)
        .then(() => {
          let title = "Incident updated successfully";

          if (this.$route.name === "new-incident")
            title = "Incident created successfully";

          this.$notify({
            group: "notifications",
            title: title,
            type: "success",
            position: "top right",
          });
          this.$emit("setLoading", false);
          this.$emit("close");
        })
        .catch((err) => {
          console.error(err);
          this.$emit("setLoading", false);
          this.$emit("close");
        });

      this.$store.dispatch("map/setIncidentTemp", {
        saved: true,
        id: this.newId,
      });
    },

    handleSave() {
      this.errors = this.$v.$invalid;

      if (this.errors) {
        this.composeErrors();
        return;
      }

      this.$emit("setLoading", true);
      this.$v.$touch();
      this.save();
      // this.$refs.upDownManager.handleUpload()

      this.saveExisting();
    },
    handleCancel() {
      this.cancel();
      this.$emit("close");
    },

    composeErrors() {
      var errorList = [];

      var titleExists = this.$v.incident.title.required;

      if (!titleExists) {
        errorList.push("Incident title is required");
      }

      this.validationErrors = errorList;
    },

    closeOtherInputs(inputType) {
      console.log(`Close Other Inputs for type: ${inputType}`);

      if (inputType != "department") {
        this.$refs.departmentInput.closeThisInput();
      }

      if (inputType != "user") {
        this.$refs.userInput.closeThisInput();
      }

      if (inputType != "asset") {
        this.$refs.assetInput.closeThisInput();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
div.edit-incident-form {
  padding: 0px 10px 0px 10px;
  color: var(--new-light-text);
}

div.c-dark-theme .edit-incident-form {
  background-color: #2a2b36;
}

.is-complete {
  margin-left: 4px;
  margin-top: 6px;
  flex-grow: 1;
}

.save-button {
  flex-grow: 0;
}

.form-group {
  margin-bottom: 5px;
  position: relative;
  &--error {
    border-bottom: 2px solid var(--bwx-error);
  }
}

div.btn {
  float: right;
}

.row {
  margin: 0px;
}

div.fields-button {
  display: flex;
  flex-direction: row;
  align-content: justify;
}
button.close {
  cursor: pointer;
}

img.arrow-icon {
  margin: 5px;
  width: 20px;
}

button.arrow-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  display: block;
  float: right;
}

div.errorMsg {
  color: #856404;
  background-color: #fff3cd;
  width: 100%;
  border-top: 1px solid #ddd1ab;
  border-bottom: 1px solid #ddd1ab;
}

li.errorMsg {
  margin-bottom: 0rem;
}

ul.errors {
  margin-bottom: 0rem;
  padding-inline-start: 0px;
  list-style-type: none;
}
</style>
<style lang="scss">
div.save-row {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 5px;
  justify-content: space-between;
}
</style>

<template>
  <div class="plus-icon">
    <button
      v-if="notAssets || isAdmin"
      class="btn btn-ghost plus-icon"
      :disabled="$route.name === `new-${categorySingle}`"
      @click.stop="navigateNewEntity(`${categoryMultiple}`)"
    >
      <img
        class="plus-icon c-d-dark-none"
        src="@/assets/img/nav/plus-light.svg"
        :alt="altTag()"
      />
      <img
        class="plus-icon c-d-default-none"
        src="@/assets/img/nav/plus-dark.svg"
        :alt="altTag()"
      />
    </button>
  </div>
</template>
<script>
export default {
  name: "PlusIcon",
  props: {
    titleSingle: String,
    titleMultiple: String,
    categorySingle: String,
    categoryMultiple: String,
  },
  computed: {
    notAssets() {
      return this.categorySingle !== "asset";
    },
    isAdmin() {
      return this.$store.state.user.isAdmin;
    },
  },
  methods: {
    altTag() {
      return `New ${this.titleSingle}`;
    },
    setActive(name) {
      // set the active dropdown
      if (this.$route.name !== "map") this.activeDropdown = name;
    },
    mapRouteNameToEntityName(routeName) {
      if (
        routeName === "task-detail" ||
        routeName === "tasks" ||
        routeName === "new-task"
      ) {
        return "tasks";
      } else if (
        routeName === "asset-detail" ||
        routeName === "assets" ||
        routeName === "new-asset"
      ) {
        return "assets";
      } else if (
        routeName === "incident-detail" ||
        routeName === "incidents" ||
        routeName === "new-incident"
      ) {
        return "incidents";
      } else if (
        routeName === "emergency-detail" ||
        routeName === "emergencies" ||
        routeName === "new-emergency"
      ) {
        return "emergencies";
      }
    },
    navigateNewEntity(categoryMultiple) {
      this.$router.push({
        path: `/${categoryMultiple}/new`,
        query: { ...this.$route.query },
      });
      this.setActive(this.mapRouteNameToEntityName(categoryMultiple));
    },
    scrollToTop() {
      this.$el.parentElement.scrollTop = 0;
    },
  },
};
</script>
<style scoped>
button.btn.btn-ghost.plus-icon {
  padding: 0px;
  border-width: 0px;
}
div.plus-icon {
  flex-grow: 0;
}
img.plus-icon {
  width: 20px;
  margin: 5px;
}
</style>

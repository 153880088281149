<template>
  <div class="nav-asset-table nav-table-bg">
    <div class="items" :class="{ 'd-none': false }" v-if="!detailShowing">
      <div v-for="item in filteredItems" :key="item._id">
        <nav-table-row
          v-if="item.properties.skyfall.name"
          :title="item.properties.skyfall.name"
          :description="item.properties.skyfall.content || ''"
          :itemID="item._id"
          :createUser="item.idCreateUser"
          :createdAt="item.createdAt"
          :categorySingle="categorySingle"
          @editRow="editRow"
          @deleteRow="deleteRow"
        />
      </div>
    </div>
    <edit-asset
      v-if="detailShowing"
      :id="detailShowing"
      @close="handleCloseDetail"
    />
  </div>
</template>
<script>
import NavTableRow from "./NavTableRow";
import EditAsset from "@/components/asset/EditAsset.vue";

export default {
  name: "NavAssetTable",
  components: {
    NavTableRow,
    EditAsset,
  },
  props: {
    categorySingle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      detailShowing: null,
    };
  },
  computed: {
    data() {
      // console.log("NavAssetTable::computed::data called");

      let arrayCopy = this.$store.state.map.assets.slice().reverse();
      return arrayCopy;
    },
    filteredItems() {
      return this.data;
    },
  },
  watch: {
    "$route.name": function (name) {
      if (this.inApplicableRoute())
        if (name === "new-asset") this.detailShowing = "new";
        else this.detailShowing = this.$route.params.id;
    },
    "$route.params.id": function (value) {
      if (this.inApplicableRoute()) {
        this.detailShowing = value;
        this.$store.dispatch("ui/setSidebarShow", true);
      }
    },
  },
  created() {
    if (this.inApplicableRoute()) {
      if (this.$route.name === "new-asset") this.detailShowing = "new";
      else this.detailShowing = this.$route.params.id;
    }
  },
  methods: {
    deleteRow(id) {
      this.$emit("scrollToTop");

      let query = { ...this.$router.currentRoute.query };
      query.when = `${Date.now()}`;

      let path = this.$router.currentRoute.path;

      this.$store.dispatch(`assets/deleteById`, id).then(() => {
        // If we were viewing the detail page for the entity which is
        // being deleted, navigate up to the parent, list of these entities

        if (path == `/assets/${id}`) {
          this.$router.push({
            name: "assets",
            query: query,
          });
        }
      });
    },
    editRow(id) {
      this.$emit("scrollToTop");
      this.$router.push({
        path: `/assets/${id}`,
        query: { ...this.$route.query },
      });
    },
    handleCloseDetail() {
      console.log("NavAssetable::handleCloseDetail");
      this.detailShowing = null;
      this.$router.push({ path: `/assets`, query: { ...this.$route.query } });
    },
    inApplicableRoute() {
      // returns true if we are in a valid route for this entity type
      return (
        this.$route.name === "assets" ||
        this.$route.name === "asset-detail" ||
        this.$route.name === "new-asset"
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>

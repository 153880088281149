<template>
  <div class="nav-emergency-table nav-table-bg">
    <div class="items" :class="{ 'd-none': false }" v-if="!detailShowing">
      <div v-for="(item, index) in emergencies" :key="`${item._id}-${index}`">
        <nav-table-row
          v-if="item.title"
          :title="item.title"
          :description="item.notes || ''"
          :itemID="item._id"
          :createUser="item.idCreateUser"
          :createdAt="item.createdAt"
          :categorySingle="categorySingle"
          @editRow="editRow"
          @deleteRow="deleteRow"
        />
      </div>
    </div>
    <edit-emergency
      v-if="detailShowing"
      :id="detailShowing"
      @close="handleCloseDetail"
    />
  </div>
</template>
<script>
import NavTableRow from "./NavTableRow";
import { mapState } from "vuex";
import EditEmergency from "@/components/emergency/EditEmergency";

export default {
  name: "NavEmergencyTable",
  components: {
    NavTableRow,
    EditEmergency,
  },
  props: {
    categorySingle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      detailShowing: null,
    };
  },
  computed: {
    ...mapState("map", ["emergencies"]),
  },

  watch: {
    "$route.name": function (name) {
      if (this.inApplicableRoute())
        if (name === "new-emergency") this.detailShowing = "new";
        else this.detailShowing = this.$route.params.id;
    },
    "$route.params.id": function (value) {
      if (this.inApplicableRoute()) {
        this.detailShowing = value;
        this.$store.dispatch("ui/setSidebarShow", true);
      }
    },
  },
  created() {
    if (this.inApplicableRoute()) {
      if (this.$route.name === "new-emergency") this.detailShowing = "new";
      else this.detailShowing = this.$route.params.id;
    }
  },
  methods: {
    editRow(id) {
      this.$emit("scrollToTop");
      this.$router.push({
        path: `/emergencies/${id}`,
        query: { ...this.$route.query },
      });
    },
    deleteRow(id) {
      this.$emit("scrollToTop");

      let query = { ...this.$router.currentRoute.query };
      query.when = `${Date.now()}`;

      let path = this.$router.currentRoute.path;

      this.$store.dispatch(`emergencies/deleteById`, id).then(() => {
        // If we were viewing the detail page for the entity which is
        // being deleted, navigate up to the parent, list of these entities

        if (path == `/emergencies/${id}`) {
          this.$router.push({
            name: "emergencies",
            query: query,
          });
        }
      });
    },
    handleCloseDetail() {
      console.log("NavEmergencyTable::handleCloseDetail");
      this.detailShowing = null;
      this.$router.push({
        path: `/emergencies`,
        query: { ...this.$route.query },
      });
    },
    inApplicableRoute() {
      // returns true if we are in a valid route for this entity type
      return (
        this.$route.name === "emergencies" ||
        this.$route.name === "emergency-detail" ||
        this.$route.name === "new-emergency"
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.ui.darkMode }">
    <TheSidebar />
    <CWrapper>
      <TheHeader :mapContext="true" />
      <div class="c-body position-relative">
        <main class="c-main position-relative">
          <portal-target name="modal" />
          <!-- :key="$route.path" -->
          <router-view />
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar"
import TheHeader from "./TheHeader"

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader
  },
  props: {
    noMapPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

main.c-main.position-relative {
  padding-top: 0px;
  display: flex;
  align-items: stretch;
}
</style>

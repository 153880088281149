<template>
  <div class="container pl-0 pr-1">
    <span class="ml-1">{{ tag }}</span>
    <button class="btn btn-ghost plus-button p-0" @click.stop="toggleInput">
      <img
        class="create-button c-d-dark-none"
        src="@/assets/img/nav/plus-light.svg"
        alt="Add"
      />
      <img
        class="create-button c-d-default-none"
        src="@/assets/img/nav/plus-dark.svg"
        alt="Add"
      />
    </button>
    <div class="content">
      <CCol sm="12" class="pl-0">
        <CBadge class="pill single" shape="pill" v-if="data">
          <span class="identifier pl-1 pr-1">
            {{ objectToString(data) }}
          </span>
        </CBadge>
      </CCol>
    </div>
    <div class="wrapper">
      <div
        class="input-modal"
        id="input-modal"
        v-if="showInput"
        @click.stop="focusInput"
      >
        <CMultiSelect
          ref="multiSelect"
          :selected="selected"
          :search="true"
          :options="options"
          @update="createEntry"
          :multiple="false"
          inline
        ></CMultiSelect>
        <button
          class="done btn btn-outline-success mt-2"
          @click.stop="toggleInput"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
export default {
  name: "PillSingleSelect",
  props: {
    // data to be populated into pills (currently selected items)
    data: {
      required: true,
      validator(value) {
        var valid = !(value instanceof Array);
        if (!valid)
          console.error(
            `Validation check failed for data in PillInputManager \nExpected: any (not array) || null \nReceived `,
            typeof value
          );
        return valid;
      },
    },
    tag: String, // description to be put above component
    src: {
      // the data or data source for MultiSelect
      required: true,
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      enteredValue: null,
      showInput: false,
      options: [],
      strSrc: [],
    };
  },
  components: {
    VueTypeaheadBootstrap,
  },
  updated: function () {
    // todo not working
    this.$nextTick(function () {
      this.focusInput();
    });
  },
  created() {
    // CMultiSelect requires a list of strings as input for the selectable options
    this.strSrc = this.src.map((e) => {
      return this.objectToString(e);
    });

    this.options = this.mapToOptions(this.strSrc);
  },
  computed: {
    selected() {
      if (this.data)
        return this.src.filter((e) => {
          return e._id === this.data._id;
        });
    },
  },
  watch: {
    // todo delete
    data(value) {
      if (value && !this.selected) {
        var arr = [];
        for (var i = 0; i < this.src.length; i++) {
          // selected's index in options
          var entry = this.src[i];
          var fromSelected = this.data.filter((e) => {
            return e._id === entry._id;
          })[0];
          if (fromSelected) {
            arr.push(i.toString());
          }
        }
        return arr;
      }
    },
    // todo fix or delete
    "$refs.multiSelect.classes": function (value) {
      console.log("classList: ", value);
    },
  },
  methods: {
    mapToOptions(arr) {
      // maps strings to objects compatible with CMultiSelect
      return arr.map((e, i) => {
        return {
          value: i.toString(),
          text: e,
        };
      });
    },
    removeEntry() {
      this.$emit("update:data", null);
    },
    createEntry(event) {
      var selectedElements = this.src[event[0]];
      this.$emit("update:data", selectedElements);
    },
    toggleInput() {
      this.showInput = !this.showInput;

      if (this.showInput) {
        this.$emit("close-other-inputs", this.type);
      }
    },
    closeThisInput() {
      if (this.showInput) {
        this.showInput = false;
      }
    },
    focusInput() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    objectToString(obj) {
      // object -> human readable identifier, usually name or title
      if (this.type === "user") {
        return obj.name;
      } else if (this.type === "asset") {
        if (obj.properties && (obj.properties.name || obj.properties.skyfall))
          return obj.properties.name || obj.properties.skyfall.name;
        else return "undefined";
      } else if (this.type === "task" || this.type === "incident") {
        return obj.title;
      } else {
        return obj;
      }
    },
  },
};
</script>

<style>
div.container {
  width: 100%;
}
div.content {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
}

button.plus-button {
  float: right;
}

.input-modal {
  background-color: var(--new-light-bg);
  position: absolute;
  z-index: 69;
  top: 0px;
  left: 0px;
  width: 200px;
  border: 1px solid var(--new-light-border);
  border-radius: 5px;
  padding: 4px;
  margin-top: 0px;
}

input {
  margin-bottom: 10px;
}

.wrapper {
  position: relative;
}

.pill.single {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--new-light-border);
  margin: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;
  width: fit-content;
  vertical-align: middle;
  align-items: center;
}

.pill.single.c-dark-theme {
  background-color: #2a2b36;
}

.create-button {
  width: 20px;
}

.done {
  background-color: var(--new-light-bg);
  color: var(--matte-green);
  border-radius: 0.25rem;
  float: right;
}

.c-multi-select-options {
  height: 200px;
  overflow-y: scroll;
}

span.identifier {
  vertical-align: middle;
  font-size: 14px;
}

span {
  font-weight: 400;
}

div.input-modal#input-modal {
  padding: 8px;
}

div.input-modal#input-modal input.c-multi-select-search {
  margin: 0px;
  margin-bottom: 10px;
  color: A8A8A8;
}

div.input-modal#input-modal div.c-multi-select-options {
  margin: 0px;
  border: 1px solid rgb(216, 219, 224);
  border-radius: 0px;
}

div.input-modal#input-modal
  div.c-multi-select.c-multi-select-inline.c-multi-select-multiple {
  padding: 0px;
  border-width: 0px;
  background-color: #f8f9fa;
}

div.input-modal#input-modal div.c-multi-select-option {
  color: A8A8A8;
}
</style>

export default {
  data: function () {
    return {
      isEditing: false,
      cachedData: null,
      loading: false
    }
  },
  mounted() {
    this.load()
  },
  watch: {
    data: function (newVal, oldVal) {
      this.load()
    }
  },
  methods: {
    save() {
      this.isEditing = false
      this.loading = true
    },
    cancel() {
      this.form = { ...this.cachedData }
      this.isEditing = false
      this.loading = false
      if (this.$refs.upDownManager) {
        this.$refs.upDownManager.resetUnsavedFiles()
      }
    },
    reset() {
      this.loading = false
      this.form = { ...this.cachedData }
    },
    load() {
      this.cachedData = { ...this.data }
      this.form = { ...this.data }
    }
  }
}

<template>
  <div>
    <CRow>
      <div v-if="errors" class="form-group alert errorMsg">
        <ul class="errors">
          <li v-for="error in validationErrors" :key="error" class="errorMsg">
            {{ error }}
          </li>
        </ul>
      </div>
    </CRow>
    <div ref="form" class="edit-emergency-form">
      <CRow class="fields-button pt-3">
        <CCol class="description-notes px-0" sm="11">
          <CRow>
            <CCol sm="12" class="pl-1">
              <CInput
                :value.sync="emergency.title"
                addLabelClasses="label"
                placeholder="Enter name of emergency"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" class="pl-1">
              <CTextarea
                :value.sync="emergency.notes"
                addLabelClasses="label"
                placeholder="Enter details here"
                class="bwx-textarea"
                rows="5"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol class="buttons mt-0 px-0" sm="1">
          <button class="arrow-button" @click.stop="$emit('close')">
            <img
              class="arrow-icon c-d-dark-none"
              src="@/assets/img/nav/chevron-down-light.svg"
            />
            <img
              class="arrow-icon c-d-default-none"
              src="@/assets/img/nav/chevron-down-dark.svg"
            />
          </button>
        </CCol>
      </CRow>
      <CRow class="save-row ml-1">
        <div class="btn btn-outline-success save-button" @click="handleSave">
          Save
        </div>
      </CRow>
      <div v-if="showConfirmation" class="edit-emergency-form__confirm">
        <emergency-confirmation @confirm="handleConfirm" @deny="handleDeny" />
      </div>
    </div>
    <div class="loading-wrapper">
      <loading
        :isFullPage="false"
        v-if="isLoading"
        :active="isLoading"
      ></loading>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import EmergencyConfirmation from "@/components/emergency/EmergencyConfirmation";
import editFormMixin from "@/mixins/editFormMixin";
import Loading from "vue-loading-overlay";

export default {
  name: "EditEmergency",
  components: {
    EmergencyConfirmation,
    Loading,
  },
  mixins: [editFormMixin],
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      validationErrors: [],
      errors: false,
      showConfirmation: false,
      confirmed: false,
      isLoading: false,
      emergency: {},
    };
  },
  validations: {
    emergency: {
      title: {
        required,
      },
      notes: {},
    },
  },
  created() {
    this.$store.dispatch("ui/setModalLoading", true);
    this.isLoading = true;

    if (this.id !== "new") {
      this.$store.dispatch("emergencies/fetchById", this.id).then((res) => {
        this.emergency = res.data;
      });
    }

    this.$store.dispatch("ui/setModalLoading", false);
    this.isLoading = false;
  },
  methods: {
    setLoading(value) {
      this.isLoading = value;
    },
    handleConfirm() {
      this.confirmed = true;
      this.showConfirmation = false;
      this.handleSave();
    },
    handleDeny() {
      this.confirmed = false;
      this.showConfirmation = false;
    },
    handleSave() {
      this.errors = this.$v.$invalid;

      if (this.errors) {
        this.composeErrors();
        return;
      }

      this.$emit("setLoading", true);
      this.$v.$touch();

      if (!this.$route.params.id || this.$route.params.id === "new") {
        if (!this.confirmed) {
          this.showConfirmation = true;
        } else {
          // loading
          const data = {
            ...this.emergency,
            idMap: this.$route.query.mapId,
          };
          this.$store
            .dispatch("emergencies/create", data)
            .then(() => {
              this.$emit("close");
            })
            .catch((err) => {
              console.error(err);
              this.loading = false;
              this.$emit("close");
            });
        }
      } else {
        const data = {
          data: {
            ...this.emergency,
            idMap: this.$route.query.mapId,
          },
          id: this.$route.params.id,
        };
        this.$store
          .dispatch("emergencies/updateById", data)
          .then(() => {
            this.loading = false;
            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
            this.$emit("close");
          });
      }
    },
    handleCancel() {
      this.cancel();
      this.$emit("close");
    },

    composeErrors() {
      var errorList = [];

      var titleExists = this.$v.emergency.title.required;

      if (!titleExists) {
        errorList.push("Emergency name is required");
      }

      this.validationErrors = errorList;
    },
  },
};
</script>
<style lang="scss" scoped>
div.edit-emergency-form {
  padding: 0px 10px 0px 10px;
  color: var(--new-light-text);
}

div.c-dark-theme .edit-emergency-form {
  background-color: #2a2b36;
}

.form-group {
  margin-bottom: 5px;
  position: relative;
  &--error {
    border-bottom: 2px solid var(--bwx-error);
  }
}

div.btn {
  float: right;
}

.row {
  margin: 0px;
}

div.fields-button {
  display: flex;
  flex-direction: row;
  align-content: justify;
}
button.close {
  cursor: pointer;
}

img.arrow-icon {
  margin: 5px;
  width: 20px;
}

button.arrow-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  display: block;
  float: right;
}

div.errorMsg {
  color: #856404;
  background-color: #fff3cd;
  width: 100%;
  border-top: 1px solid #ddd1ab;
  border-bottom: 1px solid #ddd1ab;
}

li.errorMsg {
  margin-bottom: 0rem;
}

ul.errors {
  margin-bottom: 0rem;
  padding-inline-start: 0px;
  list-style-type: none;
}
</style>
<style lang="scss">
div.save-row {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 5px;
  justify-content: flex-end;
}
</style>

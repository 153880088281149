//TODO: coreui did it this way but I'd like to probably just refactory this into a template, seems to be causing random rendering issues and console errors
<script>
export default {
  name: "SidebarItems",
  computed: {
    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavTitle",
              _children: ["Module Manager"]
            },
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Main Demo',
            //   to: '/map/assets',
            //   icon: 'cil-drop'
            // },
            {
              _name: "CSidebarNavDropdown",
              name: "Modules",
              // route: '/manage',
              // icon: 'cil-star',
              items: [
                {
                  name: "Assets Directory",
                  to: "/assets"
                },
                {
                  name: "Task Manager",
                  to: "/tasks"
                },
                {
                  name: "Incident Manager",
                  to: "/incidents"
                }
              ]
            }
            // {
            //   _name: 'CSidebarNavDropdown',
            //   name: 'Pages',
            //   route: '/pages',
            //   icon: 'cil-star',
            //   items: [
            //     {
            //       name: 'Login',
            //       to: '/pages/login'
            //     },
            //     {
            //       name: 'Register',
            //       to: '/pages/register'
            //     },
            //     {
            //       name: 'Error 404',
            //       to: '/pages/404'
            //     },
            //     {
            //       name: 'Error 500',
            //       to: '/pages/500'
            //     }
            //   ]
            // },
          ]
        }
      ]
    }
  }
}
</script>

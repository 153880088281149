<template>
  <div class="emergency-confirmation border-danger bg-white" @click.stop>
    <div class="emergency-confirmation__inner">
      <div class="title text-danger">
        <h4>
          {{ title }}
        </h4>
        <h5>
          {{ subtitle }}
        </h5>
      </div>
    </div>
    <div class="emergency-confirmation__footer">
      <div class="btn btn-danger yes-btn" @click="confirm">
        {{ yesText }}
      </div>
      <div class="btn btn-success no-btn" @click="deny">
        {{ noText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmergencyConfirmation",
  data() {
    return {
      title: "Are you sure you want to declare this emergency?",
      subtitle: "Declaring an emergency will immediately alert all users.",
      yesText: "Yes",
      noText: "No"
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm")
      this.$notify({
        group: "notifications",
        title: "Emergency Alert Created",
        text: "You have created an emergency alert.",
        type: "error",
        position: "top center"
      })
    },
    deny() {
      this.$emit("deny")
    }
  }
}
</script>
<style lang="scss" scoped>
.emergency-confirmation {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  &__inner {
    padding: 20px;
  }
  &__footer {
    padding: 20px;
    padding-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.yes-btn {
  margin-right: 0.4rem;
}
.no-btn {
  margin-left: 0.4rem;
}
.btn {
  width: 80px;
}
</style>

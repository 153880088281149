<template>
  <div class="nav-task-table nav-table-bg">
    <div class="items" :class="{ 'd-none': false }" v-if="!detailShowing">
      <div v-for="item in filteredItems" :key="item._id">
        <nav-table-row
          v-if="item.title"
          :title="item.title"
          :description="item.notes || ''"
          :itemID="item._id"
          :createUser="item.idCreateUser"
          :createdAt="item.createdAt"
          :categorySingle="categorySingle"
          @editRow="editRow"
          @deleteRow="deleteRow"
        />
      </div>
    </div>
    <edit-task
      v-if="detailShowing"
      :id="detailShowing"
      @close="handleCloseDetail"
    />
  </div>
</template>
<script>
import NavTableRow from "./NavTableRow";
import EditTask from "@/components/task/EditTask";

export default {
  name: "NavTaskTable",
  components: {
    NavTableRow,
    EditTask,
  },
  props: {
    categorySingle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hideCompleted: true,
      detailShowing: null,
    };
  },
  computed: {
    data() {
      // console.log("NavTaskTable::computed::data called");
      return this.$store.state.map.tasks.slice().reverse();
    },
    filteredItems() {
      if (this.hideCompleted && this.data) {
        return this.data.filter((item) => item.complete === false);
      } else {
        return this.data;
      }
    },
  },
  watch: {
    "$route.name": function (name) {
      if (this.inApplicableRoute())
        if (name === "new-task") this.detailShowing = "new";
        else this.detailShowing = this.$route.params.id;
    },
    "$route.params.id": function (value) {
      if (this.inApplicableRoute()) {
        this.detailShowing = value;
        this.$store.dispatch("ui/setSidebarShow", true);
      }
    },
  },
  created() {
    if (this.inApplicableRoute()) {
      if (this.$route.name === "new-task") this.detailShowing = "new";
      else this.detailShowing = this.$route.params.id;
    }
  },
  methods: {
    deleteRow(id) {
      this.$emit("scrollToTop");

      let query = { ...this.$router.currentRoute.query };
      query.when = `${Date.now()}`;

      let path = this.$router.currentRoute.path;

      this.$store.dispatch(`tasks/deleteById`, id).then(() => {
        // If we were viewing the detail page for the entity which is
        // being deleted, navigate up to the parent, list of these entities

        if (path == `/tasks/${id}`) {
          this.$router.push({
            name: "tasks",
            query: query,
          });
        }
      });
    },
    editRow(id) {
      this.$emit("scrollToTop");
      this.$router.push({
        path: `/tasks/${id}`,
        query: { ...this.$route.query },
      });
    },
    handleCloseDetail() {
      console.log("NavTaskTable::handleCloseDetail");
      this.detailShowing = null;
      this.$router.push({ path: `/tasks`, query: { ...this.$route.query } });
    },
    inApplicableRoute() {
      // returns true if we are in a valid route for this entity type
      return (
        this.$route.name === "tasks" ||
        this.$route.name === "task-detail" ||
        this.$route.name === "new-task"
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>

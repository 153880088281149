<template>
  <div class="nav-table-row">
    <div class="text-col">
      <p class="title">
        {{ title }}
      </p>
      <p class="description" v-clampy="3">
        {{ description }}
      </p>
      <p class="created" v-if="showCreator">
        <span v-if="creatorName">{{ creatorName }}</span>
        <span v-if="createdAt"> on {{ createDate }}</span>
      </p>
    </div>
    <div class="icons-col">
      <button
        class="btn btn-ghost"
        v-if="notAssets || isAdmin"
        @click="$emit('deleteRow', itemID)"
        @click.stop
      >
        <img
          class="plus-icon c-d-dark-none delete-icon"
          src="@/assets/img/nav/delete-light.svg"
        />
        <img
          class="plus-icon c-d-default-none delete-icon"
          src="@/assets/img/nav/delete-dark.svg"
        />
      </button>
      <button
        class="btn btn-ghost"
        :disabled="clickDisabled"
        @click="$emit('editRow', itemID)"
        @click.stop
      >
        <img
          class="arrow-icon c-d-dark-none arrow-icon"
          src="@/assets/img/nav/chevron-left-light.svg"
        />
        <img
          class="arrow-icon c-d-default-none arrow-icon"
          src="@/assets/img/nav/chevron-left-dark.svg"
        />
      </button>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
export default {
  name: "NavTableRow",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    itemID: {
      type: String,
      required: false,
      default: null,
    },
    createUser: {
      default: "",
    },
    createdAt: {
      type: String,
      default: "",
    },
    categorySingle: {
      type: String,
      default: "",
    },
  },
  computed: {
    clickDisabled() {
      return this.$route.params.id == this.itemID;
    },
    createDate() {
      const dateTime = DateTime.fromISO(this.createdAt);
      return `${dateTime.toFormat("d-MMM")} at ${dateTime.toFormat("hh:mm")}`;
    },
    creatorName() {
      if (!this.createUser) return "";

      if (typeof this.createUser != "object") return "Created";

      if (!this.createUser.hasOwnProperty("name")) return "Created";

      return this.createUser.name;
    },
    showCreator() {
      return (
        (this.creatorName || this.createdAt) && (this.notAssets || this.isAdmin)
      );
    },
    notAssets() {
      return this.categorySingle !== "asset";
    },
    isAdmin() {
      return this.$store.state.user.isAdmin;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-table-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  p {
    color: var(--matte-navy-blue);
    margin: 0;
    line-height: 1.3;
  }
  p.title {
    font-size: 18px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;
    flex-grow: 1;
  }
  p.description {
    font-size: 12px;
    margin-bottom: 2px;
  }
  p.created {
    font-size: 12px;
  }
  button {
    background: none;
    border: none;
    text-align: left;
    outline: 0px;
    font-weight: 400;
    padding: 0px;
  }
  button.item:disabled,
  button.item[disabled] {
    transition: border 0.3s linear;
  }
  button.item:disabled p,
  button.item[disabled] p {
    color: hsl(215, 100%, 100%);
  }
}

img.delete-icon,
img.arrow-icon {
  width: 20px;
  margin: 8px 4px;
}

div.text-col {
  flex-grow: 1;
  min-width: 0;
  margin-right: 4px;
}

div.icons-col {
  display: flex;
  flex-direction: row;
}
.c-dark-theme {
  p {
    color: hsl(215, 12%, 72%);
  }
}
</style>

<template>
  <div class="category-icon">
    <img
      class="categroy-icon c-d-dark-none"
      :src="require(`@/assets/img/sidebar/${this.categorySingle}-light.svg`)"
    />
    <img
      class="categroy-icon c-d-default-none"
      :src="require(`@/assets/img/sidebar/${this.categorySingle}-dark.svg`)"
    />
  </div>
</template>
<script>
export default {
  name: "CategoryIcon",
  props: {
    titleSingle: String,
    titleMultiple: String,
    categorySingle: String,
    categoryMultiple: String,
  },
  computed: {},
};
</script>
<style scoped>
div.category-icon {
  flex-grow: 0;
}
img.categroy-icon {
  width: 20px;
  margin: 5px;
  margin-right: 10px;
}
</style>

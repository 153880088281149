<template>
  <div class="outer">
    <div class="category">
      <category-icon
        :titleSingle="titleSingle"
        :titleMultiple="titleMultiple"
        :categorySingle="categorySingle"
        :categoryMultiple="categoryMultiple"
      >
      </category-icon>
      <div class="category-title">{{ titleMultiple }}</div>
      <plus-icon
        :titleSingle="titleSingle"
        :titleMultiple="titleMultiple"
        :categorySingle="categorySingle"
        :categoryMultiple="categoryMultiple"
      >
      </plus-icon>
      <arrow-icon
        :titleSingle="titleSingle"
        :titleMultiple="titleMultiple"
        :categorySingle="categorySingle"
        :categoryMultiple="categoryMultiple"
        @toggle-table="toggleTable"
      >
      </arrow-icon>
    </div>
    <nav-asset-table
      v-if="categorySingle == 'asset' && active"
      :categorySingle="categorySingle"
      @scrollToTop="scrollToTop"
    />
    <nav-task-table
      v-if="categorySingle == 'task' && active"
      :categorySingle="categorySingle"
      @scrollToTop="scrollToTop"
    />
    <nav-incident-table
      v-if="categorySingle == 'incident' && active"
      :categorySingle="categorySingle"
      @scrollToTop="scrollToTop"
    />
    <nav-emergency-table
      v-if="categorySingle == 'emergency' && active"
      :categorySingle="categorySingle"
      @scrollToTop="scrollToTop"
    />
  </div>
</template>
<script>
import CategoryIcon from "./CategoryIcon";
import PlusIcon from "./PlusIcon";
import ArrowIcon from "./ArrowIcon";
import NavAssetTable from "./NavAssetTable";
import NavIncidentTable from "./NavIncidentTable";
import NavTaskTable from "./NavTaskTable";
import NavEmergencyTable from "./NavEmergencyTable";
export default {
  name: "CustomNavDropdown",
  components: {
    CategoryIcon,
    PlusIcon,
    ArrowIcon,
    NavAssetTable,
    NavTaskTable,
    NavIncidentTable,
    NavEmergencyTable,
  },
  props: {
    titleSingle: String,
    titleMultiple: String,
    categorySingle: String,
    categoryMultiple: String,
    active: [Boolean],
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return ["nav-dropdown", "dark-toggle", this.active ? "active" : ""];
    },
  },
  mounted() {},
  methods: {
    updateURL(value) {
      if (this.active)
        setTimeout(() => {
          // this prevents a visual bug where closing repopulates assets and makes it appear as if
          // it is closing
          this.$emit("updateURL", value);
        }, 100);
      else this.$emit("updateURL", value);
    },
    isExpanded() {
      let name = this.$route.name;

      if (name == this.categoryMultiple) return true;

      if (name == `new-${this.categorySingle}`) return true;

      return false;
    },
    toggleTable() {
      if (this.isExpanded())
        this.$router.push({ path: `/`, query: { ...this.$route.query } });
      else
        this.$router.push({
          name: this.categoryMultiple,
          query: { ...this.$route.query },
        });
    },
    scrollToTop() {
      this.$el.parentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.outer {
  position: relative;
}
.category {
  padding: 10px;
  background-color: hsla(180, 00%, 70%, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 10px;
}
.category-title {
  flex-grow: 1;
  font-size: 18px;
  font-family: "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
}
</style>

<style lang="scss">
button.btn:focus {
  box-shadow: none;
}
.items {
  background-color: var(--new-light-bg);
}

.c-dark-theme .items {
  background-color: #2a2b36;
}
</style>

<template>
  <CSidebar
    class="bwx-sidebar"
    :minimize="minimize"
    unfoldable
    size="lg"
    :show="show"
    @update:show="(value) => $store.dispatch('ui/setSidebarShow', value)"
  >
    <CSidebarBrand class="d-md-down-none" to="/maps">
      <!-- img
        class="brand-logo c-d-dark-none"
        :src="require('@/assets/brand/brickworks-skyfall-horizontal-light.svg')"
        alt=""
      / -->
      <img
        class="brand-logo"
        :src="require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')"
        alt=""
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        view-box="0 0 110 134"
      />
    </CSidebarBrand>
    <CSidebarNav>
      <sidebar-module-nav />
    </CSidebarNav>
  </CSidebar>
</template>

<script>
import SidebarItems from "./SidebarItems";
import NavAssetTable from "@/components/module-manager/NavAssetTable";
import NavTaskTable from "@/components/module-manager/NavTaskTable";
import NavIncidentTable from "@/components/module-manager/NavIncidentTable";
import NavMapLayers from "@/components/module-manager/NavMapLayers";
import SidebarModuleNav from "../components/module-manager/SidebarModuleNav.vue";

export default {
  name: "TheSidebar",
  components: {
    NavMapLayers,
    NavAssetTable,
    NavIncidentTable,
    NavTaskTable,
    SidebarModuleNav,
  },
  extends: SidebarItems,
  data() {
    return {
      testItems: ["hello", "hello2"],
    };
  },
  computed: {
    show() {
      return this.$store.state.ui.sidebarShow;
    },
    minimize() {
      return this.$store.state.ui.sidebarMinimize;
    },
  },
  methods: {
    openAssetsInModal() {},
  },
};
</script>
<style lang="scss" scoped>
.brand-logo {
  max-width: 150px;
}
::v-deep.c-sidebar .c-sidebar-nav-dropdown-toggle {
  font-size: 1.2em;
}

.c-sidebar .c-sidebar-brand.d-md-down-none {
  background-color: var(--new-white-bg);
  margin-right: -4px;
  background-color: #434e65;
  justify-content: left;
  padding-left: 16px;
}
</style>

<template>
  <div class="presenter-logo">
    <img
      class="presenter-logo"
      v-if="hasLogo"
      :src="logo"
      alt="presenter logo"
      draggable="false"
    />
    <img
      class="presenter-logo"
      v-else
      src="@/assets/img/app/transparent.gif"
      alt="presenter logo"
      draggable="false"
    />
  </div>
</template>
<script>
import SettingsDropdownItem from "@/components/ui/SettingsDropdownItem";
import NotificationSettings from "@/components/ui/NotificationSettings";
export default {
  name: "PresenterLogo",
  components: {
    SettingsDropdownItem,
    NotificationSettings,
  },
  props: {
    eventInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasLogo: false,
      logo: "https://via.placeholder.com/1x1",
    };
  },
  created() {
    this.getLogo();
  },
  methods: {
    async getLogo() {
      this.loaded = false;
      const id = this.$route.query.mapId;

      const mapData = await this.$store.dispatch("map/loadMapData", id);
      const idCompany = mapData.data.idCompany._id;

      const companyData = await this.$store.dispatch(
        "companies/fetchById",
        idCompany
      );

      var img = null;

      if (
        companyData.data.hasOwnProperty("image-key-dark") &&
        companyData.data["image-key-dark"] != null
      ) {
        const imgDark = companyData.data["image-key-dark"];

        if (imgDark.hasOwnProperty("img") && imgDark.img != null) {
          img = imgDark.img;
        }
      }

      if (img == null) {
        if (
          companyData.data.hasOwnProperty("image-key-light") &&
          companyData.data["image-key-light"] != null
        ) {
          const imgLight = companyData.data["image-key-light"];

          if (imgLight.hasOwnProperty("img") && imgLight.img != null) {
            img = imgLight.img;
          }
        }
      }

      if (img != null) {
        var base64String = "";

        var binary = "";
        var bytes = new Uint8Array(img.data.data);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        base64String = btoa(binary);

        this.logo = `data:image/${img.contentType};base64,${base64String}`;
        this.hasLogo = true;
        return;
      }

      this.logo = "https://via.placeholder.com/1x1";
    },
  },
};
</script>

<style lang="scss">
img.presenter-logo {
  object-fit: contain;
  height: 40px;
}
</style>

import { render, staticRenderFns } from "./NavTaskTable.vue?vue&type=template&id=31102627&scoped=true&"
import script from "./NavTaskTable.vue?vue&type=script&lang=js&"
export * from "./NavTaskTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31102627",
  null
  
)

export default component.exports